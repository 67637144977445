import * as React from "react"
import { Link } from "gatsby"

import Title from "../components/Title"
import Text from "../components/Text"
import Seo from "../components/seo"
import Container from "../components/Container"

const NotFoundPage = () => (
  <Container as='main' classes='error-section'>
    <Title as="h1" size="1">
      Błąd: 404
    </Title>
    <Text>
      Ta podstrona nieistnieje lub została usunięta.
      <br />
      <Link to="/">Wróć do strony głównej</Link>
    </Text>
  </Container>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
